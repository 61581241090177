import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const FamilyCount = () => {
  const [editable, setEditable] = useState(false);
  const [Image, setImage] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [Id, setId] = useState("");
  const [count, setcount] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(6); // Default month is June
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const baseUrl2 = process.env.REACT_APP_BASEURL2;
  const baseUrl3 = process.env.REACT_APP_BASEURL3;

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    setInputValue(""); // Reset input value on cancel
  };

  const handleSaveClick = () => {
    setEditable(false);
    // Perform save operation here with inputValue
    // For example, you can send it to an API or update state in parent component
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const data1 = async () => {
    try {
      const data = await axios.get(`${baseUrl}/admin/familyImage/image`);

      setcount(data.data.details[0].count);
      setImage(data.data.details[0].image);
      setId(data.data.details[0]._id);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEditable(false);

    try {
      const formData = new FormData();

      if (inputValue.trim() !== "") {
        formData.append("count", inputValue);
      } else {
        formData.append("count", count);
      }
      formData.append("image", newImage);

      const editdata = await axios.post(
        `${baseUrl}/admin/familyImage/update?id=${Id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(editdata);
      toast.success("Family image updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Family image update failed");
    }
  };

  useEffect(() => {
    data1();
  }, []);

  const [families, setFamilies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [cur, setCur] = useState(1);

  const fetchFamilies = async (page = 1) => {
    try {
      setFamilies([]);
      const { data } = await axios.get(
        `${baseUrl3}/admin/familyImage/familyrank?page=${page}&limit=${itemsPerPage}&month=${selectedMonth}`
      );
      setFamilies(data.details);
      // setCurrentPage(data.pagination.currentPage);
      // setTotalPages(data.pagination.totalPages);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch families");
    }
  };

  useEffect(() => {
    fetchFamilies();
  }, [itemsPerPage, selectedMonth]);

  const handlePageChange = (page) => {
    fetchFamilies(page);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCur(1);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  return (
    <>
      <section className="content-header">
        <h1>Manage Family Count</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageTag">Manage Family</Link>
          </li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Manage</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Family Count*</label>
                    {editable ? (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control"
                            name="count"
                            placeholder="Title"
                            defaultValue={count}
                            value={inputValue}
                            onChange={handleChange}
                            style={{ width: "60%" }}
                          />
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            placeholder="select Image"
                            onChange={(e) => {
                              setNewImage(e.target.files[0]);
                            }}
                            style={{ width: "60%" }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger "
                            style={{ marginLeft: "20px", marginRight: "10px" }}
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </span>
                      </>
                    ) : (
                      <>
                        <span style={{ display: "flex" }}>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            placeholder="Title"
                            value={count}
                            style={{ width: "60%" }}
                            readOnly
                          />

                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ marginLeft: "20px" }}
                            onClick={handleEditClick}
                          >
                            Edit
                          </button>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Old Icon*</label>
                    <br />

                    <img src={`${baseUrl}${Image}`} alt="" height={"80px"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="box box-warning">
              <div className="box-header with-border">
                <h3 className="box-title">Family Ranking</h3>
              </div>
              <div className="box-body text-dark" style={{ color: 'black' }}>
                <div className="form-group">
                  <label htmlFor="itemsPerPage">Items per page:</label>
                  <select
                    id="itemsPerPage"
                    className="form-control"
                    style={{ width: 'auto', display: 'inline-block', marginLeft: '10px' }}
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="selectMonth">Select Month:</label>
                  <select
                    id="selectMonth"
                    className="form-control"
                    style={{ width: 'auto', display: 'inline-block', marginLeft: '10px' }}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                  >
                    <option value={0}>January</option>
                    <option value={1}>February</option>
                    <option value={2}>March</option>
                    <option value={3}>April</option>
                    <option value={4}>May</option>
                    <option value={5}>June</option>
                    <option value={6}>July</option>
                    <option value={7}>August</option>
                    <option value={8}>September</option>
                    <option value={9}>October</option>
                    <option value={10}>November</option>
                    <option value={11}>December</option>
                  </select>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Leader Username</th>
                      <th>Family Name</th>
                      <th>Points</th>
                      <th>W. Balance</th>
                    </tr>
                  </thead>
                  {families.length == 0 ? (
                    <>Loading...</>
                  ) : (
                    <>
                      <tbody>
                        {Array.isArray(families) &&
                          families.map((family, i) => (
                            <tr key={family._id}>
                              <td>{(cur - 1) * itemsPerPage + i + 1}</td>
                              <td>{family.username}</td>
                              <td>{family.name}</td>
                              <td>{family.experince}</td>
                              <td>{family.balance}</td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  )}
                </table>
                <div className="pagination">
                  <button
                    className="btn btn-default"
                    disabled={currentPage === 1}
                    onClick={() => {
                      setCur(cur - 1);
                      handlePageChange(cur - 1);
                    }}
                  >
                    Prev
                  </button>
                  <button
                    className="btn btn-default"
                    disabled={currentPage === totalPages}
                    onClick={() => {
                      setCur(cur + 1);
                      handlePageChange(cur + 1);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FamilyCount;
