import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";

const CoinPanelTransactionLists = () => {
  const { rechargeHistory, rechargeHistorys, rechargeRecievedHistorys } =
    useContext(global);
  const { uniqueId } = useParams();
  // console.log(p);
  // console.log(rechargeHistory);
  useEffect(() => {
    rechargeHistorys({ userId: uniqueId });
    // rechargeRecievedHistorys({ userId: coinDetails.uniqueId });
  }, []);

  const submitHandler = async (dataDetails) => {
    // e.preventDefault();
    const { data } = await axios.post(
      `${process.env.REACT_APP_BASEURL2}/admin/return/coins`,
      dataDetails
    );
    // console.log(dataDetails);
    // console.log(data);
    if (data.success == "1") {
      toast.success(data.message);
      // console.log(data);
    }
  };

  return (
    <>
      {/* Content Header (Page header) */}
      <section className="content-header">
        <h1>Transaction Lists Sent To User</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Title</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            {/* /.box */}
            <div className="box">
              {/* /.box-header */}
              <div className="box-body">
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Admin</th>
                      <th>Coin</th>
                      <th>Date/Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(rechargeHistory) &&
                      rechargeHistory?.map((item, index) => {
                        let i = index + 1; // Start index from 1
                        return (
                          <tr>
                            <td>{i}</td>
                            <td>{item?.userId}</td>
                            <td>
                              <label htmlFor="" className="label label-success">
                                {item?.coin}
                              </label>
                            </td>
                            <td>{new Date(item?.createdAt).toDateString()}</td>
                            <td
                              className=""
                              onClick={() => {
                                const res = window.confirm(
                                  "Are you sure you want to return coin"
                                );
                                if (res == true) {
                                  const data = {
                                    id : item._id,
                                    senderName: item.coinAgency,
                                    coins: item.coin,
                                    createdAt: item.createdAt,
                                    username: item.userId,
                                  };
                                  submitHandler(data);
                                  // console.log(data);
                                }
                              }}
                            >
                              <button
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  fontWeight: "bold",
                                  border: "none",
                                  padding: ".3rem",
                                  paddingLeft: ".5rem",
                                  paddingRight: ".5rem",
                                }}
                              >
                                Return
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {/* PHP session message removed */}
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
      {/* /.content */}
    </>
  );
};

export default CoinPanelTransactionLists;
