import React, { useContext, useEffect, useState,useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import {  toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import _ from "lodash";

const Widthdrawal = ({ status }) => {
  const {
    widthdrawalListsUpdate,
  } = useContext(global);
  const [widthdrawal, setWidthdrawal] = useState([]);
  const widthdrawalLists = async (status) => {
    try {
      setWidthdrawal([])
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/withdrawal/pending?status=${status}&page=${page}&limit=${limit}`
      );
      console.log(data);

      // let res = await data.json();

      if (data.success == 1) {
        // toast.success(data.message);
        setWidthdrawal(data.details);
        setCount(data.total)

     
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  
  const [id, setId] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");





  useEffect(() => {
    widthdrawalLists(status);
  }, [status,page,limit]);

  const searchUser = async (value) => {
    try {
      // setSearchData(filterValue);
      setWidthdrawal([])
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/admin/withdrawal/search?search=${value}&status=${status}&page=${page}`
      );
      setWidthdrawal(data.details);
    } catch (error) {
      console.log(error.message);
    }
  };



  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(widthdrawal.map(item => ({ _id: item._id, username: item.userId?.username, number: item.userId?.number, amount: item.coins,createdAt:item.createdAt })));
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedItems.some(selected => selected._id === item._id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter(selected => selected._id !== item._id));
    } else {
      setSelectedItems([...selectedItems, { _id: item._id, username: item.userId?.username,number: item.userId?.number, amount: item.coins,createdAt:item.createdAt }]);
    }
  };

  
  const exportToCsv = () => {
    try {
      const csvContent = selectedItems.map(item => ({
        username: item.username,
        number: item.number,
        amount: item.amount,
        date: new Date(item.createdAt).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
      }));

      const csvRows = [
        [ "Username", "Number","Amount","Date"],
        ...csvContent.map(item => Object.values(item))
      ];

      const csvString = csvRows.map(row => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      saveAs(blob, 'exported_data.csv');
    } catch (error) {
      console.error("Error exporting data:", error);
    }
  };
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value)
      if(value!="")
      searchUser(value);
    else widthdrawalLists(status)
    }, 1000),
    []
  );
  return (
    <>
      <section className="content-header">
        <h1>Widthdrawal List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Widthdrawal List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                <div className="form-group col-xs-4">
                    <label htmlFor="limit">Results per page:</label>
                    <select
                      id="limit"
                      value={limit}
                      onChange={(e) => {
                        setPage(1)
                        setLimit(Number(e.target.value))
                      }}
                      className="form-control"
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={500}>500</option>
                    </select>
                  </div>
                  <div className="col-xs-4"></div>
                  <div className="form-group col-xs-4">
                    <label htmlFor="search">Search:</label>
                    <input
                      type="text"
                      id="search"
                      className="form-control"
                      onChange={(e) => handleSearchChange(e.target.value)}
                    />
                  </div>
                  
                </div>
                <div className="col-md-2">
                  <button className="btn btn-info" style={{ outline: "none" }} onClick={toggleSelectAll}>
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>
                <div>
                  <button type="submit" className="btn btn-info" onClick={exportToCsv} style={{ marginRight: "1rem" }}>
                    Export Data
                  </button>
                </div>
                <table id="example1" className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={selectAll}
                        />
                      </th>
                      <th>SR.</th>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Number</th>
                      <th>Coins</th>
                      <th>ACC NO</th>
                      <th>BANK NAME</th>
                      <th>IFSC CODE</th>
                      <th>STATUS</th>
                      {status !== "2" && <th>ACTION</th>}
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {widthdrawal.length === 0 ? (
                      "Loading..."
                    ):
                    Array.isArray(widthdrawal) && widthdrawal.map((item, index) => {
                      let i =   (page - 1) * limit + (index + 1);;
                      let d = new Date(item?.createdAt);
                      return (
                        <tr key={item._id}>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedItems.some(selected => selected._id === item._id)}
                              onChange={() => handleCheckboxChange(item)}
                            />
                          </td>
                          <td>{i}</td>
                          <td>{new Date(d).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })}</td>
                          <td>{item?.userId?.username}</td>
                          <td>{item?.userId?.name}</td>
                          <td>{item?.userId?.number}</td>
                          <td>{item?.coins}</td>
                          <td>{item?.accountNo}</td>
                          <td>{item?.bankName}</td>
                          <td>{item?.ifscCode}</td>
                          <td>
                            {item.status == "0" ? (
                              <span className="label label-info">Pending</span>
                            ) : item.status === "1" ? (
                              <span className="label label-success">Approved</span>
                            ) : (
                              <span className="label label-danger">Rejected</span>
                            )}
                          </td>
                          <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                {status != "2" && (
                                  <li className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      style={{ color: "black" }}
                                      data-toggle="dropdown"
                                      href="#"
                                      aria-expanded="false"
                                    >
                                      Action <span className="caret" />
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                      {item.status == 0 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              // aprrovedHost({
                                              //   id: item._id,
                                              //   status: "1",
                                              // });
                                              let status = 1;
                                              widthdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              // aprrovedHost({
                                              //   id: item._id,
                                              //   status: "2",
                                              // });
                                              let status = 2;
                                              setId(item._id);
                                              widthdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                          <li role="presentation">
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to={`/admin/viewwidthdrawal/${item.userId._id}`}
                                            >
                                              View
                                            </Link>
                                          </li>
                                        </>
                                      ) : item.status == 1 ? (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              // aprrovedHost({
                                              //   id: item._id,
                                              //   status: "2",
                                              // });
                                              let status = 2;
                                              setId(item._id);
                                              widthdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Reject
                                            </Link>
                                          </li>
                                          <li role="presentation">
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to={`/admin/viewwidthdrawal/${item.userId._id}`}
                                            >
                                              View
                                            </Link>
                                          </li>
                                        </>
                                      ) : (
                                        <>
                                          <li
                                            role="presentation"
                                            onClick={() => {
                                              // aprrovedHost({
                                              //   id: item._id,
                                              //   status: "1",
                                              // });
                                              let status = 1;
                                              setId(item._id);
                                              widthdrawalListsUpdate(
                                                item._id,
                                                status
                                              );
                                            }}
                                          >
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to=""
                                            >
                                              Approve
                                            </Link>
                                          </li>
                                          <li role="presentation">
                                            <Link
                                              role="menuitem"
                                              tabIndex={-1}
                                              to={`/admin/viewwidthdrawal/${item.userId._id}`}
                                            >
                                              View
                                            </Link>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </li>
                                )}
                              </ul>
                            </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="row">
                      <div className="col-md-5 text-bold">
      
                          Total entries : {count} 
                  
                      </div>
                      <div className="pagination col-md-5">
                      <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      >
                        Previous
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setPage((prev) => (prev < Math.ceil(count / limit) ? prev + 1 : prev))
                        }
                      >
                        Next
                      </span>
                    </li>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Widthdrawal;
