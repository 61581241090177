import React, { useContext, useEffect, useState } from "react";
import "../Css/pagesstyle.css";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from 'axios'


const BdLiveUser = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  // const { liveuser, viewliveuser, setLivePage, livePage } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [bdLists,setBdLists] = useState([])
  const [liveuser, setliveuser] = useState([]);
  const [livePage, setLivePage] = useState(1);
  const [bd, setbd] = useState("")

  

  const getBdDetails = sessionStorage.getItem("bd");
  const parseDetails = JSON.parse(getBdDetails)
  const getLiveUser = async () => {
    try {
        const {data} = await axios.get(`${process.env.REACT_APP_BASEURL1}/bdPanel/bdrecord?bd=${parseDetails.uniqueId}`)
       
        if(data.success == '1') {
            setBdLists(data.details)
       
        }
    } catch (error) {
        console.log(error.message)
    }
  }
// console.log(livePage)
// console.log(parseDetails.uniqueId)
  const viewliveuser = async () => {
    try {
      let {data} = await axios.get(`${baseUrl}/admin/livegifts/liveusers?page=${livePage}&bd=${parseDetails.uniqueId}`)
      // console.log(data)
      setliveuser(data.details);
    } catch (err) {
     console.log(err.message);
    }
  };

console.log(liveuser)
//   useEffect(() => {
// getLiveUser()
//   },[])
  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  const currentUsers =liveuser&& liveuser.length > 0 ? liveuser?.slice(indexOfFirstUser, indexOfLastUser):[];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  
    const totalPages =liveuser ? Math.ceil(liveuser.length / entriesPerPage) :Math.ceil(0 / entriesPerPage) ;
  

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };
  useEffect(() => {
    viewliveuser();
    }, [liveuser]);
  // console.log("Current User ",currentUsers)

  return (
    <>
      <section className="content-header">
        <h1>Live Users</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Live Users</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>
                  <div className="entries-per-page col-md-6 ">
                    <label htmlFor="entries">Entries per page:</label>
                    <select
                      id="entries"
                      value={entriesPerPage}
                      onChange={(e) =>
                        setEntriesPerPage(parseInt(e.target.value))
                      }
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
                <div className="success-message"></div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>image</th>
                      <th>Channel Name</th>
                      <th>Status</th>
                      <th>Date/Time</th>
                      <th>Live Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentUsers) &&
                      currentUsers.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        return (
                          <tr key={index}>
                            <td>{i}</td>
                            <td>
                          <img
                            src={`${baseUrl}${item?.image}`}
                            alt=""
                            style={{ height: "3rem", width: "3rem" }}
                          />
                        </td>
                            <td>{item?.channelName}</td>
                            <td>
                              <span className="label label-success">{item.status}</span>
                            </td>
                            <td>{item?.createdAt}</td>
                            <td>{item?.totalTime =="NaN" ? 0 : item?.totalTime} min</td>
                          </tr>
                        );
                      })}
                    {/* Repeat rows as needed */}
                  </tbody>
                </table>
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      {/* Total entries: <b>{liveuser.length}</b> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BdLiveUser;
