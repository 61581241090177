import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const User = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10); // Default entries per page
  const [search, setSearch] = useState("");
  const [user, setUser] = useState([]);
  const [count, setCount] = useState(0);

  // Fetch users from API
  const getUsers = async (page, limit) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/users?page=${page}&limit=${limit}`
      );
      setUser(response.data.users);
      setCount(response.data.count);
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Fetch initial users on component mount
  useEffect(() => {
    getUsers(currentPage, entriesPerPage);
  }, [currentPage, entriesPerPage]);

  // Handle pagination click
  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Search function
  const agencyadminlistsearch = async (searchValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL3}/admin/search?search=${searchValue}`
      );
      setUser(response.data.details); // Update user state with search results
    } catch (error) {
      toast.error(error.message);
    }
  };

  // Handle search input change with debounce
  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearch(value);
      agencyadminlistsearch(value);
    }, 1000),
    []
  );

  // Render pagination numbers
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(count / entriesPerPage); i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePagination(i)}
        >
          <button className="page-link">{i}</button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <>
      <section className="content-header">
        <h1>Manage Users</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form>
                <div className="col-md-12" style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <div className="row" style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-6">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>

                    <div className="col-md-5" style={{ display: "flex", justifyContent: "center" }}>
                      <div className="main-data-single-field" style={{ width: "fit-content" }}>
                        <span style={{ fontWeight: "bold", marginRight: "8px" }}>Search</span>
                        <input
                          type="text"
                          id="pname"
                          name="pname"
                          style={{ borderRadius: "4px", borderStyle: "groove", width: "150px" }}
                          onChange={(e) => handleSearchChange(e.target.value)}
                          placeholder="Search User"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="box-body">
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Image</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Coins</th>
                      <th>Receiving Coins</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user.map((item, index) => (
                      <tr key={index}>
                        <td>{entriesPerPage * (currentPage - 1) + index + 1}</td>
                        <td><img src={`${baseUrl}${item.picture}`} width="30px" height="30px" style={{ borderRadius: "50%" }} alt="user" /></td>
                        <td>{item.username}</td>
                        <td>{item.name}</td>
                        <td>{item.number}</td>
                        <td><span className="label label-warning">{item.coin}</span></td>
                        <td><span className="label label-success">{item.recievingCoins}</span></td>
                        <td>{item.active ? <span className="label label-success">Active</span> : <span className="label label-danger">Inactive</span>}</td>
                        <td>
                          <ul className="admin-action btn btn-default">
                            <li className="dropdown">
                              <a className="dropdown-toggle" style={{ color: "black" }} data-toggle="dropdown" href="#" aria-expanded="false">
                                Action <span className="caret" />
                              </a>
                              <ul className="dropdown-menu dropdown-menu-right">
                                <li role="presentation">
                                  <Link role="menuitem" tabIndex={-1} to={`/admin/ViewUser?id=${item._id}`}>
                                    View
                                  </Link>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="row">
                  <div className="col-md-5">
                    <ul className="pagination">
                      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button onClick={() => handlePagination(currentPage - 1)} className="page-link" disabled={currentPage === 1}>Previous</button>
                      </li>
                      <li className={`page-item ${currentPage === Math.ceil(count / entriesPerPage) ? "disabled" : ""}`}>
                        <button onClick={() => handlePagination(currentPage + 1)} className="page-link" disabled={currentPage === Math.ceil(count / entriesPerPage)}>Next</button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div className="total-entries" style={{ float: "right", marginTop: "20px" }}>
                      Total entries: <b>{count}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default User;
