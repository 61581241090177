import React, { useContext, useEffect, useState } from "react";
import { Link ,useSearchParams} from "react-router-dom";
import { global } from "../../Context/Context";
import { value } from "../../config/data";
import axios from "axios";

const HostWithdrawalLists = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  const { users, user } = useContext(global);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(5);
  const [widthdrawalList,setWithdrawalLists] = useState([])
  const [loading,setLoading] = useState(false)
  let [searchParams] = useSearchParams();

const adminData = searchParams.get('agency');
const startDate = searchParams.get('start');
const endDate = searchParams.get('end');
  //Get Bds lists 
  const getBdLists = async() => {
 try {
  setLoading(true)
    const {data} = await axios.get(`https://mastishortss.com/video/admin/withdrawal/data?start=${startDate}&end=${endDate}&agency=${adminData}`)
   
    // console.log(data)
    if(data.success == '1') {
setLoading(false)
      setWithdrawalLists(data.details)
    }
 } catch (error) {
    console.log(error.message)
 }
  }
  // console.log(splitData)


  useEffect(() => {
    getBdLists()
  }, [startDate,endDate]);


  // Logic to paginate the users
  const indexOfLastUser = currentPage * entriesPerPage;
  const indexOfFirstUser = indexOfLastUser - entriesPerPage;
  // const currentUsers = user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers =
  //   searchData.length > 0
  //     ? searchData.slice(indexOfFirstUser, indexOfLastUser)
  //     : user.slice(indexOfFirstUser, indexOfLastUser);
  // const currentUsers = widthdrawalList.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination
  // const totalPages = Math.ceil(user.length / entriesPerPage);
  const totalPages = Math.ceil(
    user?.length / entriesPerPage
  );

//   const searhUser = async () => {
//     try {
//     //   setSearchData(user);
//       const { data } = await axios.get(
//         `${process.env.REACT_APP_BASEURL1}/admin/search?search=${search}`
//       );
//       // console.log(data);
//     //   setSearchData(data);
//     } catch (error) {
//       console.log(error.message);
//     }
//   };


  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`} // Apply active style conditionally
        >
          <button onClick={() => paginate(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {/* <!-- Content Header (Page header) --> */}
      <section className="content-header">
        <h1>Bd Withdrawal</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Users</li>
        </ol>
      </section>
      {/* <!-- Main content --> */}

      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <form method="post" id="getPdf">
                <div
                  class="col-md-12"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <div className="row " style={{ padding: "16px 0px" }}>
                    <div className="entries-per-page col-md-6 ">
                      <label htmlFor="entries">Entries per page:</label>
                      <select
                        id="entries"
                        value={entriesPerPage}
                        onChange={(e) =>
                          setEntriesPerPage(parseInt(e.target.value))
                        }
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </div>
{/* 
                    <div
                      class="col-md-5"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                         Start Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            // setStartDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                      <div
                        class="main-data-single-field"
                        style={{ width: "fit-content" }}
                      >
                        <span
                          style={{ fontWeight: "bold", marginRight: "8px" }}
                        >
                          End Date
                        </span>
                        <span>
                          <input
                            type="date"
                            id="pname"
                            name="pname"
                            style={{
                              borderRadius: "4px",
                              borderStyle: "groove",
                              width: "150px",
                            }}
                            onChange={(e) => {
                            //   setSearch(e.target.value);
                            // setEndDate(e.target.value)
                            }}
                            placeholder="Searh User"
                          />
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </form>
              <div className="box-body">
                {/* <!-- Content here --> */}
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                    <th>Sr.</th>
                      <th>Image</th>
                      <th>Host Username</th>
                      <th>Withdrawl coins</th>
                      <th>USD Amount</th>
                      <th>Target USD Amount</th>
                      <th>Total</th>
                      <th>INR</th>
                      <th>Agency Commission(10%)</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {loading ?<td colSpan={4} style={{textAlign:'center'}}><h6 className="" style={{width:"100%",margin:"0 auto"}}>Loading...</h6> </td> : Array.isArray(widthdrawalList) &&
                      widthdrawalList?.map((item, index) => {
                        let i = indexOfFirstUser + index + 1;
                        let inr = ((+(item?.coins/value.USD).toFixed(2) + +(item?.bonus?.targetCommission))*value.USDINR).toFixed(2);
                        let commission = (inr*value.agencyComm).toFixed(2);
                        return (
                          <tr key={index}>
                             <td>{i}</td>
                            <td>
                              <img
                                src={`${item?.userId?.picture}`}
                                style={{ width: "50px",height:"50px" }}
                                alt="user"
                              />
                            </td>
                            <td>{item?.userId?.username}</td>
                             {/* <td>{item?.admin}</td> */}
                            <td>{item?.coins}</td>
                            <td>{(item?.coins/value.USD).toFixed(2)}</td>
                            <td>{(item?.bonus.targetCommission)}</td>
                            <td>{(+(item?.coins/value.USD).toFixed(2) + +(item?.bonus.targetCommission))}</td>
                            <td><span style={{fontWeight:"bold"}}>Rs </span>{inr}</td>
                            <td><span style={{fontWeight:"bold"}}>Rs </span>{commission}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {/* <!-- Pagination links here --> */}
                <div className="row">
                  {/* Pagination */}

                  <div className="col-md-5">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage - 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {currentPage > 2 && (
                        <li className="page-item disabled"></li>
                      )}
                      {renderPageNumbers()}
                      {currentPage < totalPages - 1 && (
                        <li className="page-item disabled"></li>
                      )}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          onClick={() => paginate(currentPage + 1)}
                          className="page-link dfsdfasdf"
                          disabled={currentPage === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="total-entries"
                      style={{ float: "right", marginTop: "20px" }}
                    >
                      Total entries:{" "}
                      {/* <b>
                        {searchData?.length > 0
                          ? searchData?.length
                          : user?.length}
                      </b> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- /.content --> */}
    </>
  );
};
const activeStyle = {
  borderColor: "red",
  color: "red",
};
export default HostWithdrawalLists;
