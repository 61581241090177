import React, { useState } from "react";
import { Link ,useNavigate} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const AddAds = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
    let history = useNavigate()
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
console.log(formData)
    try {
        event.target.reset();
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL1}/admin/banner/addAd`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      toast.success(" Image successfully added");

      history('/admin/addd')
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to add Image");
    }
  };
  return (
    <>
      <section className="content-header">
        <h1>Add Splash Image</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
         
          <li className="active">Add Ad</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-10">
            <form
              role="form"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  
                  <div className="form-group">
                    <label>Image*</label>
                    <input type="file" className="form-control" name="image" />
                  </div>

                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success pull-right"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddAds;
