import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";

const Ads = () => {
  const baseUrl = process.env.REACT_APP_BASEURL1;
  
  const [viewBanner, setViewBanner] = useState();

  const [de, setDe] = useState();
  useEffect(() => {
    viewBannerr();
  }, []); 
  const viewBannerr = async()=>{
    try {
        
        const res = await fetch(`${baseUrl}/admin/banner/getAds`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                },
                });
                const data = await res.json();
                if(data.success==1){
                    setViewBanner(data.details);
                    toast.success(data.message)
                }
                else{
                    toast.error(data.message)
                    }
    } catch (error) {
        toast.danger(error.message)
    }
  }
  // Logic to paginate the users




  return (
    <>
      <section className="content-header">
        <h1>Manage Ads</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Manage Add</li>
        </ol>
      </section>
      {/* Main content */}
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box-header">
              <h3 className="box-title">
                <Link
                  to="/admin/addAd"
                  style={{ fontSize: "14px" }}
                  className="btn btn-block btn-success btn-xs"
                >
                  Add Add
                </Link>
              </h3>
            </div>
            <div className="box">
              <div className="box-body">
                <div className="row " style={{ padding: "16px 0px" }}>


                  <div
                    class="col-md-5"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      class="main-data-single-field"
                      style={{ width: "fit-content" }}
                    >
                  
                  
                    </div>
                  </div>
                </div>
                {/* Success message */}
                <div
                  className="success-message"
                  style={{ display: "none" }}
                ></div>
                <table
                  id="example1"
                  className="table table-bordered table-striped"
                >
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Banner Image</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {/* Sample data rows */}

                    {Array.isArray(viewBanner) &&
                      viewBanner.map((item, index) => {
                        return (
                          <tr>
                            <td>{index+1}</td>
                            <td>
                              <img
                                src={`${baseUrl}${item.image}`}
                                style={{
                                  width: "80px",
                                  aspectRatio: "2/1",
                                  objectFit: "contain",
                                }}
                              />
                            </td>
                            <td>
                              <ul
                                className="admin-action btn btn-default"
                                style={{
                                  backgroundColor: "#f4f4f4",
                                  color: "#fff !important",
                                }}
                              >
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"
                                    style={{ color: "black" }}
                                    data-toggle="dropdown"
                                    href="#"
                                    aria-expanded="false"
                                  >
                                    Action <span className="caret" />
                                  </a>
                                  <ul className="dropdown-menu dropdown-menu-right">
                                  
                                    <li role="presentation">
                                      <Link
                                        role="menuitem"
                                        tabIndex={-1}
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to delete this item?"
                                          );
                                          if (confirmDelete) {
                                            $.ajax({
                                              url: `${process.env.REACT_APP_BASEURL3}/admin/banner/deleteAds?id=${item._id}`,
                                              type: "post",
                                              dataType: "json",
                                              success: function (res) {
                                                if (res.success == 1) {
                                                  toast.success(res.message);
                                                  setDe(1);
                                                } else {
                                                  toast.error(res.message);
                                                }
                                              },
                                            });
                                          }
                                        }}
                                      >
                                        Delete
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        );
                      })}

                    {/* Repeat this block for each data row */}
                  </tbody>
                </table>
                {/* Pagination links */}

                {/* <!-- Pagination links here --> */}
               
              </div>
              {/* /.box-body */}
            </div>
            {/* /.box */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      </section>
    </>
  );
};

export default Ads;
