import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";

const SendCoinPanel = () => {
  const coinData = sessionStorage.getItem("coin");
  const coinDetails = JSON.parse(coinData);
  const [coinDataValue, setCoinDataValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkBal, setCheckBal] = useState();
  // console.log(coinDetails);
  // fetch user details
  useEffect(() => {
    if (!sessionStorage.getItem("coin")) {
      window.location.href = "/coin";
    }
  }, []);
  const checkuser = () => {
    var userId = $("input[name='userId']").val();

    $("#form-error1").remove();
    $.ajax({
      url: `${process.env.REACT_APP_BASEURL}/coin/recharge/sendCoinfetchuser`,
      type: "get",
      dataType: "json",
      data: {
        userId,
      },
      success: function (res) {
        if (res.success === 1) {
          if (res.details.username != "") {
            var div = `
                  <div className="form-error1" id="form-error1" style="display:flex; color:gray; margin-top: 15px;" >
                    <img src=${res.details.picture} style='border-radius:50% ; height:50px; width:50px;' alt="User Profile" />
                    <span style='margin-left: 15px; '>${res.details.username} <br /> ${res.details.name}</span>
                  </div>
                `;
          }

          $(".form-error4").html("");
          $("#user").append(div);
          // var container = document.getElementById("user");
          // var secondDiv = container.querySelector("div:nth-child(2)");
        } else {
          $(".form-error4").html("no user found");
        }
      },
    });
  };

  const subbbb = (e) => {
    e.preventDefault();
    var userId = $("input[name='userId']").val();
    var coin = $("input[name='coin']").val();
    let text = "This field cannot be empty";
    let objData = {
      username: userId,
      coin: coin,
    };
    let arrData = [...coinDataValue, objData];
    if (userId == "") {
      $(".form-error4").html(text);
    }
    if (coin == "") {
      $(".form-error5").html(text);
    } else if (userId != "" && coin != "") {
      setLoading(true);
      $.ajax({
        url: `${process.env.REACT_APP_BASEURL2}/coin/recharge/sendCoin`,
        type: "patch",
        dataType: "json",
        data: {
          userId, /// sender name
          coin,
          type: 1,
          to: 2,
          coinAgency: coinDetails.uniqueId,
          id: coinDetails._id,
        },
        success: function (res) {
          if (res.success === 1) {
            setCoinDataValue(arrData);
            var userId = $("input[name='userId']").val("");
            var coin = $("input[name='coin']").val("");
            setTimeout(() => {
              setLoading(false);
            }, 2000);
            setLoading(false);
            toast.success(res.message);
            sessionStorage.setItem("coin", JSON.stringify(res.CoinPanel));
            setTimeout(() => {
              $("#form")[0].reset();
            }, 2000);
          } else {
            toast.error(res.message);
          }
        },
      });
    }
  };
  const getDataOfCoinPanel = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASEURL1}/coin/recharge/sendCoinfetchagency?userId=${coinDetails.uniqueId}`
      );
      setCheckBal(data.details);
      // console.log(data);
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(coinDataDetails);
  useEffect(() => {
    getDataOfCoinPanel();
  }, []);

  // console.log(coinDataValue);
  return (
    <>
      <section className="content-header">
        <div
          className=""
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1>Send Coins</h1>
          </div>
          <div>
            <h4>Balance</h4>
            <p>{checkBal ? checkBal?.coins : "loading..."}</p>
          </div>
        </div>

      
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              onSubmit={subbbb}
              encType="multipart/form-data"
              id="form"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Send</h3>
                </div>
                <div className="box-body">
                  <div className="form-group" id="user">
                    <label>Username*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="userId"
                      placeholder="username"
                      onChange={() => {
                        $(".form-error4").html("");
                        checkuser();
                      }}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Coins*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coin"
                      placeholder="Coins"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5 text-danger"></div>
                  </div>
                  {/* <div className="form-group">
                    <label>Type</label>
                    <input
                      type="text"
                      className="form-control"
                      name="type"
                      hidden={true}
                      readOnly={true}
                      value={2}
                      placeholder="type"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Unique Id</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coinAgency"
                      hidden={true}
                      readOnly={true}
                      value={coinDetails?.uniqueId}
                      placeholder="uniqueId"
                      onChange={() => {
                        $(".form-error5").html("");
                      }}
                    />
                    <div className="form-error5 text-danger"></div>
                  </div> */}
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    {loading ? (
                      <p>Sending...</p>
                    ) : (
                      <input
                        type="submit"
                        className="btn btn-success pull-right"
                        name="submit"
                        defaultValue="Submit"
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              {/* /.box */}
              <div className="box">
                {/* /.box-header */}
                <div className="box-body">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th>Sr.</th>
                        <th>UserName</th>
                        <th>Coin Id</th>
                        <th>Coins</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(coinDataValue) &&
                        coinDataValue?.map((item, index) => {
                          let i = index + 1; // Start index from 1
                          return (
                            <tr>
                              <td>{i}</td>
                              <td>{item?.username}</td>
                              <td>{coinDetails?.uniqueId}</td>
                              <td>
                                <label
                                  htmlFor=""
                                  className="label label-success"
                                >
                                  {item?.coin}
                                </label>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* PHP session message removed */}
                </div>
                {/* /.box-body */}
              </div>
              {/* /.box */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </section>
      </section>
    </>
  );
};

export default SendCoinPanel;
