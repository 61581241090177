import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { global } from "../../Context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import _ from "lodash";

const HostList = ({ status }) => {
  const baseUrl = process.env.REACT_APP_BASEURL2;
  const [AgencyList, setAgencyList] = useState([]);
  const [reload, setReload] = useState(false);
  const [limit, setLimit] = useState(50);
  const [count, setCount] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const { totalHosts, totalPages, page, setPage } = useContext(global);
  const agency = sessionStorage.getItem("agency");
  const newAgency = JSON.parse(agency);
  const agencysqlId = newAgency.sqlId;
  const agencyuniqueId = newAgency.uniqueId;

  let id;
  let idd;

  if (newAgency?.old == "1") {
    id = newAgency?.sqlId;
    idd = newAgency?.uniqueId;
  } else {
    id = newAgency?.uniqueId;
    idd = newAgency?.sqlId;
  }

  const aglist1 = async () => {
    try {
      setAgencyList([]);
      const response = await axios.get(`${baseUrl}/agency/host/findpanhost`, {
        params: {
          uniqueId: id,
          uniqueIdd: idd,
          status: status,
          page: page,
          limit: limit,
        },
      });
      setAgencyList(response.data.details);
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  const searchHosts = async (query) => {
    try {
      setAgencyList([]);
      const response = await axios.get(`${baseUrl}/agency/host/searchhost`, {
        params: {
          uniqueId: id,
          uniqueIdd: idd,
          status: status,
          searchQuery: query,
        },
      });
      setAgencyList(response.data.details);
      setCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    aglist1();
  }, [status, reload, page, limit]);

  const aglist = async (status, id) => {
    try {
      const response = await axios.get(`${baseUrl}/agency/host/hostrequestsent`, {
        params: {
          id: id,
          status: status,
        },
      });

      if (response.data.success === 1) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      setReload(!reload);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = useCallback(
    _.debounce((value) => {
      setSearchQuery(value);
      searchHosts(value);
    }, 2000),
    []
  );

  return (
    <>
      <section className="content-header">
        <h1>Host List</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li className="active">Host List</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-xs-12">
            <div className="box">
              <div className="box-body">
                <div className="row">

               
                <div className="form-group col-xs-4">
                  <label htmlFor="limit">Results per page:</label>
                  <select
                    id="limit"
                    value={limit}
                    onChange={(e) => setLimit(Number(e.target.value))}
                    className="form-control"
                  >
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                  </select>
                </div>
                <div className="col-xs-4"></div>
                <div className="form-group col-xs-4">
                  <label htmlFor="search">Search:</label>
                  <input
                    type="text"
                    id="search"
                    className="form-control"
                    onChange={(e) => handleSearchChange(e.target.value)}
                  />
                </div>
                </div>
                <table id="example1" className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Date</th>
                      <th>Username</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Agency Code</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id="ts">
                    {AgencyList.length === 0 ? (
                      "No data available"
                    ) : (
                      AgencyList.map((host, i) => (
                        <tr key={host._id}>
                          <td>{count - limit * (page - 1) - i}</td>
                          <td>{new Date(host?.updatedAt).toLocaleString()}</td>
                          <td>{host?.username}</td>
                          <td>{host?.realName}</td>
                          <td>{host.number}</td>
                          <td>{newAgency.uniqueId}</td>
                          <td>
                            <span
                              className={`label ${
                                host.status == 0
                                  ? "label-info"
                                  : host.status == 1
                                  ? "label-success"
                                  : host.status == 3
                                  ? "label-primary"
                                  : host.status == 4
                                  ? "label-primary"
                                  : "label-danger"
                              }`}
                            >
                              {host.status == 0
                                ? "Pending"
                                : host.status == 1
                                ? "Approved"
                                : host.status == 3
                                ? "Pending by Agency Admin"
                                : host.status == 4
                                ? "Pending by Bd"
                                : "Rejected"}
                            </span>
                          </td>
                          <td>
                            <ul
                              className="admin-action btn btn-default"
                              style={{
                                backgroundColor: "#f4f4f4",
                                color: "#fff !important",
                              }}
                            >
                              <li className="dropdown">
                                <a
                                  className="dropdown-toggle"
                                  style={{ color: "black" }}
                                  data-toggle="dropdown"
                                  href="#"
                                  aria-expanded="false"
                                >
                                  Action <span className="caret" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-right">
                                  {host.status === "0" ? (
                                    <>
                                      <li
                                        role="presentation"
                                        onClick={() => aglist("1", host._id)}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Approved
                                        </Link>
                                      </li>
                                      <li
                                        role="presentation"
                                        onClick={() => {
                                          const confirmDelete = window.confirm(
                                            "Are you sure you want to Reject"
                                          );
                                          if(confirmDelete)  
                                          aglist("2", host._id)
                                        }}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Reject
                                        </Link>
                                      </li>
                                    </>
                                  ) : host.status === "1" ? (
                                    <>
                                      {/* <li
                                        role="presentation"
                                        onClick={() => aglist("2", host._id)}
                                      >
                                        <Link role="menuitem" tabIndex={-1} to="">
                                          Reject
                                        </Link>
                                      </li> */}
                                    </>
                                  ) : null}
                                </ul>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                {/* Pagination links */}
                <div>
                  <ul className="pagination">
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPage((prev) => Math.max(prev - 1, 1));
                        }}
                      >
                        Previous
                      </span>
                    </li>
                    <li>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setPage((prev) => prev + 1);
                        }}
                      >
                        Next
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HostList;
