import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import $ from "jquery";
import { toast } from "react-toastify";
import axios from "axios";
import { global } from "../../Context/Context";

const AddLiveGift = () => {
  const { viewLiveGiftCategorys, viewLiveGiftCategory } = useContext(global);
  // console.log(viewLiveGiftCategory)


  const navigate = useNavigate();

  // const subbbb = (e) => {
  //   e.preventDefault();
  //   var a = $("select[name='category']").val();
  //   var b = $("input[name='title']").val();
  //   var c = $("input[name='coin']").val();
  //   var d = $("input[name='image']").val();
  //   var e = $("input[name='giftimage']").val();
  //   let text = "This field cannot be empty";
  //   if (a == "") {
  //     $(".form-error1").html(text);
  //   }
  //   if (b == "") {
  //     $(".form-error2").html(text);
  //   }
  //   if (c == "") {
  //     $(".form-error3").html(text);
  //   }
  //   if (d == "") {
  //     $(".form-error4").html(text);
  //   }
  //   if (e == "") {
  //     $(".form-error5").html(text);
  //   } else if (a != "" && b != "" && c != "" && d != "") {
  //     let formData = new FormData(e.target); // Use e.target to access the form element
  //     $.ajax({
  //       url: `${process.env.REACT_APP_BASEURL}/admin/livegifts/addlivegifts`,
  //       type: "post",
  //       dataType: "json",
  //       data: formData,
  //       contentType: false,
  //       processData: false,
  //       success: function (res) {
  //         if (res.success === 1) {
  //           toast.success(res.message);
  //           setTimeout(() => {
  //             navigate("/admin/manageLiveGift");
  //           }, 1000);
  //         } else {
  //           toast.error(res.message);
  //         }
  //       },
  //     });
  //   }
  // };
  // \\\\\\\\\\\\\\\\
  const [formData, setFormData] = useState({
    title: "",
    image: "",
    coin: "",
    giftimage: null,
    category:null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {title,coin,category,image,giftimage} = formData

    // if(!title || !coin || !category || !image || !giftimage){
    //   toast.error("Please enter all the required fields")
    // }
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("coin", formData.coin);
    formDataToSend.append("category", formData.category);
    formDataToSend.append("image", formData.image);
    formDataToSend.append("giftimage", formData.giftimage);
    // console.log(formData.image);
    // return;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/admin/livegifts/addlivegifts`,
        formDataToSend
      );

      if (response) {
        toast.success(response.data.message || "Gift added successfully!");
        setFormData({
          title: "",
          image: null,
          coin: "",
          giftimage:null,
    
        });
          // Reload the page
          window.location.reload();
      } else {
        toast.error("Failed to add gift. Please try again later.");
      }

      // Clear form data if needed
    
    } catch (error) {
      console.error("Error sending notification:", error);
      toast.error("Failed to add gift. Please try again later.");
    }
  };

  // const handleUserChange = (e) => {
  //   const user = document.getElementById("ctg").value;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const imageChange = (e) => {
    console.log("image change ")
    const { name, files } = e.target;
    // console.log(files);
    const file = files[0];
    // console.log(file);
    setFormData({ ...formData, [name]: file });
  };
  // \\\\\\\\\\\\\\\\\
  useEffect(() => {
    viewLiveGiftCategorys();
  }, [formData]);

  return (
    <>
      <section className="content-header">
        <h1>Add LiveGift</h1>
        <ol className="breadcrumb">
          <li>
            <Link to="/admin">
              <i className="fa fa-dashboard"></i> Home
            </Link>
          </li>
          <li>
            <Link to="/admin/manageLiveGift">Manage LiveGift</Link>
          </li>
          <li className="active">Add LiveGift</li>
        </ol>
      </section>
      <section className="content">
        <div className="row">
          <div className="col-md-12">
            <form
              role="form"
              method="post"
              id="specialitiesForm"
              onSubmit={handleSubmit}
              encType="multipart/form-data"
            >
              <div className="box box-warning">
                <div className="box-header with-border">
                  <h3 className="box-title">Add</h3>
                </div>
                <div className="box-body">
                  <div className="form-group">
                    <label>Category List*</label>
                    <select
                      type="text"
                      id="ctg"
                      className="form-control"
                      name="category"
                      onChange={handleChange}
                      required
                      defaultValue={formData?.category}
                    >
                      <option value="">Select Gift Category</option>

                      {Array.isArray(viewLiveGiftCategory) &&
                        viewLiveGiftCategory.map((item, index) => {
                          let i = index + 1;
                          return (
                            <>
                              <option value={item._id}>{item.title}</option>
                            </>
                          );
                        })}
                    </select>
                    <div className="form-error1 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Title*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      placeholder="Title"
                      value={formData.title}
                      onChange={handleChange}
                    />
                    <div className="form-error2 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>Coin*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="coin"
                      placeholder="Coin"
                      value={formData.coin}
                      onChange={handleChange}
                    />
                    <div className="form-error3 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label>SVGA*</label>
                    <input
                      type="file"
                      className="form-control"
                      name="image"
                      onChange={imageChange}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <label> Gift Image*</label>
                    <input
                      accept="giftimage/*"
                      type="file"
                      className="form-control"
                      name="giftimage"
                      onChange={imageChange}
                    />
                    <div className="form-error4 text-danger"></div>
                  </div>
                  <div className="form-group">
                    <button type="reset" className="btn btn-danger">
                      Cancel
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success pull-right"
                      name="submit"
                      defaultValue="Submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddLiveGift;
