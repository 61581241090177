import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

let global = createContext();

var BaseUrl = process.env.REACT_APP_BASEURL;
var BaseUrl2 = process.env.REACT_APP_BASEURL2;
var BaseUrl3 = process.env.REACT_APP_BASEURL3;

const Context = ({ children }) => {
  const login = async (credentials) => {
    try {
      // console.log(credentials);
      let data = await fetch(`${BaseUrl}/admin/login`, {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      // console.log(res);
      if (res.success === 1) {
        toast.success(res.message);
        sessionStorage.setItem("data", JSON.stringify(res.details));

        setTimeout(() => {
          window.location.href = "/admin";
        }, 1000);
      } else {
        toast.error("Invalid Credentials");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Edit profile api

  const editProfile = async (credentials) => {
    try {
      // console.log(credentials);
      // let data = await fetch(`${BaseUrl}/admin/profile/editprofile`,{
      //     method : 'PUT',
      //     body : credentials,
      //     headers : {
      //         'Content-Type': 'application/json'
      //     }
      // })
      // let res = await data.json()
      // console.log(res)
      // if(res.success===1){
      //     toast.success(res.message)
      //     sessionStorage.setItem('data' , JSON.stringify(res.details))
      // }else{
      //     toast.error("Unbl to update")
      // }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const users = async () => {
    try {
      let data = await fetch(`https://mastishortss.com/video/admin/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();
      // console.log(a)
      // return a;
      setUser(a.users);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // user state

  const [user, setUser] = useState([]);

  const viewUsers = async (id) => {
    try {
      let data = await fetch(`${BaseUrl2}/admin/view_user?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();
      // console.log(data);

      setviewUsers(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // user state

  const [viewUser, setviewUsers] = useState({});

  // Manage Banner (Get api)

  const viewBannerr = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/banner/getbanner`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewBanner(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [viewBanner, setViewBanner] = useState([]);

  // edit banner (get api)
  const fetchBanner = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/banner/fetchbanner?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setfetchBanner(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchBannerr, setfetchBanner] = useState([]);

  // Manage Vip (get api)
  const viewVipp = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/vip/getVip`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewVipp(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [viewVip, setViewVipp] = useState([]);

  // edit vip (get api)
  const viewVipS = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/vip/fetchVip?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setviewVipS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [viewVipsS, setviewVipS] = useState({});

  // view vip (get api)
  const viewVipSCat = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/vip/fetchVipCat?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setviewVipSCat(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [viewVipsCatS, setviewVipSCat] = useState({});
  const [viewTag, setViewTagg] = useState([]);

  // Manage Tag (get api)
  const viewTagg = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/tag/getTag`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewTagg(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };
  //get songs
  const [songs, setSongs] = useState([]);
  const getSongs = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/songs/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setSongs(a.songs);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit tag (get api)
  const fetchTag = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/tag/fetchTag?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setfetchTag(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchTagS, setfetchTag] = useState({});

  // Manage Gifts (get api)
  const [viewGift, setViewGifts] = useState([]);

  const viewGifts = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/gifts/getgifts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewGifts(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit gift (get api)
  const fetchGift = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/gifts/fetchGift?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setfetchGift(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchGiftS, setfetchGift] = useState({});

  // Manage LiveGifts (get api)
  const [viewLiveGift, setViewLiveGifts] = useState([]);

  const viewLiveGifts = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/livegifts/getlivegifts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewLiveGifts(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit LiveGifts (get api)
  const fetchlivegif = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/livegifts/fetchlivegifts?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchlivegifS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchlivegifS, setfetchlivegifS] = useState({});

  // Manage EntryEffects (get api)
  const [viewEntryEffect, setViewEntryEffects] = useState([]);

  const viewEntryEffects = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/entryeffects/getentryeffects`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewEntryEffects(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit entryeffect (get api)
  const fetchentryeffect = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/entryeffects/fetchentryeffect?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchentryeffectS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchentryeffectS, setfetchentryeffectS] = useState({});

  // Manage Frames (get api)
  const [viewFramess, setViewFramess] = useState([]);

  const viewFrames = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/frame/getframes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewFramess(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit frame (get api)
  const fetchframes = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/frame/fetchframes?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setfetchframesS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchframesS, setfetchframesS] = useState({});

  // Manage Live Gift category (get api)

  const [viewLiveGiftCategory, setViewLiveGiftCategorys] = useState([]);

  const viewLiveGiftCategorys = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/livegiftcategory/getLiveGiftscategory`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setViewLiveGiftCategorys(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit LiveGiftscategory (get api)
  const fetchlivegift = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/livegiftcategory/fetchLiveGiftscategory?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchlivegiftS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchlivegiftS, setfetchlivegiftS] = useState({});

  // Wallpaper category (get api)

  const [viewWallpaper, setViewWallpaper] = useState([]);

  const viewWallpapers = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/wallpaper/getwallpaper`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewWallpaper(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit wallpaper (get api)
  const fetchwallpaper = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/wallpaper/fetchwallpaper?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchwallpaperS(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchwallpaperS, setfetchwallpaperS] = useState({});

  // Coin Panel (get api)

  const [viewCoin, setViewCoin] = useState([]);

  const viewCoins = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/coinPanel/getCoinPanel`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setViewCoin(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit CoinPanel (get api)

  const fetchcoinPanel = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/coinPanel/fetchCoinPanel?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchCoinPanel(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchCoinPanel, setfetchCoinPanel] = useState({});

  // Agency Panel (get api)

  const [agencyPanel, setAgencyPanel] = useState([]);

  const viewAgencyPanel = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/agencyPanel/getAgencyPanel`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setAgencyPanel(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit agencyPanel (get api)

  const fetchagencyPanel = async (id) => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/agencyPanel/fetchAgencyPanel?id=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setfetchagencyPanel(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchagencyPanell, setfetchagencyPanel] = useState({});

  // recharge history (get api)

  const [adminrechargeHistory, setadminrechargeHistory] = useState([]);

  const adminrechargeHistorys = async () => {
    try {
      let data = await fetch(`${BaseUrl}admin/recharge/getrechargeHistory`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      // console.log(data);
      let a = await data.json();
      setadminrechargeHistory(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // recharge history (get api)

  const [rechargeHistory, setrechargeHistory] = useState([]);

  const rechargeHistorys = async (value) => {
    try {
      let data = await fetch(`${BaseUrl}coin/recharge/history`, {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setrechargeHistory(a.history);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Recieved history
  const [received, setReceived] = useState([]);
  const rechargeRecievedHistorys = async (value) => {
    try {
      let data = await fetch(`${BaseUrl}/coin/recharge/received`, {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setReceived(a.received);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // level (get api)

  const [level, setlevel] = useState([]);

  const viewlevel = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/level/getlevel`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setlevel(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // edit agencyPanel (get api)

  const fetchlevel = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/level/fetchlevel?id=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setfetchlevel(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [fetchlevell, setfetchlevel] = useState({});

  // User Level
  const [userLevel, setUserLevel] = useState([]);
  const getUserLevel = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/userlevel/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setUserLevel(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateUserLevel = async (value) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/userlevel/update`, {
        method: "PATCH",
        body: JSON.stringify(value),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();
      if (a.success) {
        toast.success(a.message);
      }
      // setUserLevel(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Agency Login
  const AgencyLogin = async (credentials) => {
    try {
      // console.log(credentials);
      let data = await fetch(`${BaseUrl}/agency/login`, {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      // console.log(res);
      if (res.success === 1) {
        toast.success(res.message);
        sessionStorage.setItem("agency", JSON.stringify(res.agency));

        setTimeout(() => {
          window.location.href = "/agency/dashboard";
        }, 1000);
      } else {
        toast.error("Invalid Credentials");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  //  Agency Reset Password
  // const AgencyResetPassword = async (credentials) => {
  //   try {
  //     console.log(credentials);
  //     let data = await fetch(`${BaseUrl}/agency/passwordReset`, {
  //       method: "PATCH",
  //       body: JSON.stringify(credentials),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     let res = await data.json();
  //     console.log(res);
  //     if (res.success === 1) {
  //       toast.success(res.message);
  //       sessionStorage.setItem("data", JSON.stringify(res.details));

  //       // setTimeout(()=>{
  //       //     window.location.href='/admin'
  //       // },1000)
  //     } else {
  //       toast.error("Invalid Credentials");
  //     }
  //   } catch (err) {
  //     toast.error(err.message);
  //   }
  // };
  //   Agency Edit Profile
  // const AgencyEditProfile = async (credentials) => {
  //   try {
  //     console.log(credentials);
  //     let data = await fetch(`${BaseUrl}/agency/edit`, {
  //       method: "PATCH",
  //       body: JSON.stringify(credentials),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     let res = await data.json();
  //     console.log(res);
  //     if (res.success === 1) {
  //       toast.success(res.message);
  //       sessionStorage.setItem("data", JSON.stringify(res.details));

  //       // setTimeout(()=>{
  //       //     window.location.href='/admin'
  //       // },1000)
  //     } else {
  //       toast.error("Invalid Credentials");
  //     }
  //   } catch (err) {
  //     toast.error(err.message);
  //   }
  // };

  // Coin Login
  const CoinPanelLogin = async (credentials) => {
    try {
      // console.log(credentials);
      let data = await fetch(`${BaseUrl}/coinPanel/login`, {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      // console.log(res);
      if (res.success === 1) {
        toast.success(res.message);
        sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));

        setTimeout(() => {
          window.location.href = "/coin/dashboard";
        }, 1000);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  //  host record
  const [hostRecord, setHostRecord] = useState([]);
  const hostrecord = async (id,sqlId) => {
    try {
      let data = await fetch(`https://mastishortss.com/video/agency/host/hostrecord?id=${id}$sqlId=${sqlId}`, {
        method: "GET",
      });
      // console.log(data);
      let res = await data.json();
      console.log(res)
      return
      if (res.success === 1) {
        // console.log(res.data);
        toast.success(res.message);
        // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));
        setHostRecord(res.details);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  ////////////////////////////////////////////////
  // Get Hosts
  const [totalHosts, setTotalHosts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getHosts = async (id, status) => {
    try {
      // console.log("inside api fn",id)
      let data = await fetch(
        `${BaseUrl}agency/host/gethosts?id=${id}&status=${status}&page=${page}`,
        // `https://mastishortss.com/video/agency/host/gethosts?id=${id}&status=${status}&page=${page}`,
        {
          method: "GET",
          // body: JSON.stringify(credentials),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      let res = await data.json();
      console.log(res);

      setTotalHosts(res.details);
      setTotalPages(res.pages);
      // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));

      // setTimeout(() => {
      //   window.location.href = "/coin/dashboard";
      // }, 1000);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // fetch
  const [fetchHost, setFetchHost] = useState({});
  const fetchHosts = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/agency/host/fetchhosts?id=${id}`, {
        method: "GET",
        // body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      setFetchHost(res.details);
      // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));

      // setTimeout(() => {
      //   window.location.href = "/coin/dashboard";
      // }, 1000);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // fetch  host by status

  const [getHostByStatus, setGetHostByStatus] = useState([]);
  const getHostByStatuss = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/agency/host/gethosts?status=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      setGetHostByStatus(res.details);
      // console.log(res);
    } catch (err) {
      toast.error(err.message);
    }
  };
  const [getHostByStatus1, setGetHostByStatus1] = useState([]);
  const getHostByStatuss1 = async (id) => {
    try {
      let data = await fetch(`${BaseUrl}/agency/host/gethost?status=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      setGetHostByStatus1(res.details);
      // console.log(res);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Aprroved host
  const aprrovedHost = async (dataDetails) => {
    try {
      let data = await fetch(`${BaseUrl}/agency/host/approvehost`, {
        method: "POST",
        body: JSON.stringify(dataDetails),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let res = await data.json();
      if (res.success === 1) {
        toast.success(res.message);
        // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));
        setFetchHost(res.details);
      } else {
        toast.error("res.message");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  // console.log(fetchHost);
  // aprrovedHost();
  // ban user
  const banStatus = async (dataDetails) => {
    try {
      let data = await fetch(`${BaseUrl}/admin/userstatus`, {
        method: "POST",
        body: JSON.stringify(dataDetails),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      if (res.success === 1) {
        toast.success(res.message);
        // sessionStorage.setItem("coin", JSON.stringify(res.coinPanel));
        setFetchHost(res.details);
      } else {
        toast.error("Invalid Credentials");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // level (get api)

  const [liveuser, setliveuser] = useState([]);
  const [livePage, setLivePage] = useState(1);
  const [bd, setbd] = useState("")
  const viewliveuser = async () => {
    try {
      let data = await fetch(
        `${BaseUrl}admin/livegifts/liveusersadmin?page=${livePage}&bd=${bd}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setliveuser(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // level (get api)

  const [hostCounts, sethostCounts] = useState({});

  const viewhostCounts = async () => {
    try {
      let data = await fetch(`${BaseUrl}/agency/host/hostCountss`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      sethostCounts(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Plan (get api)

  const [planCounts, setPlanCounts] = useState([]);

  const viewPlan = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/plan/getPlan`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setPlanCounts(a.plans);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Content (get api)

  const [contentCounts, setContentCounts] = useState([]);

  const viewContent = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/content/getContent`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      let a = await data.json();

      setContentCounts(a.details);
    } catch (err) {
      toast.error(err.message);
    }
  };

  //  admin frames in user
  const [adminframes, setadminframes] = useState({});

  const viewadminframes = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/frame/getframesadmin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      setadminframes(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Planss Fetch

  const [fetchplan, setFetchPlan] = useState([]);

  const FetchPlanss = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/plan`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();
      setFetchPlan(a.users);
    } catch (err) {
      toast.error(err.message);
    }
  };

  //  admin entryEffects in user
  const [adminentryEffects, setadminentryEffects] = useState({});

  const viewadminentryEffects = async () => {
    try {
      let data = await fetch(
        `${BaseUrl}/admin/entryeffects/getentryeffectsadmin`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let a = await data.json();

      setadminentryEffects(a.data);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [getVulgar, setGetVulgar] = useState([]);
  const getVulgarResult = async () => {
    let data = await fetch(`${BaseUrl}/admin/content/getVulgar`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let a = await data.json();
    // console.log(a);
    setGetVulgar(a.details);
  };
  const [getVideo, setGetVideo] = useState([]);
  const getVideoResult = async () => {
    let data = await fetch(`${BaseUrl}/admin/video`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let a = await data.json();
    // console.log(a);
    setGetVideo(a.data);
  };
  const aproveRejectVulgar = async (value) => {
    let data = await fetch(`${BaseUrl}/admin/content/aprovereject`, {
      method: "POST",
      body: JSON.stringify({ status: value.status, id: value.id }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    let a = await data.json();
    // console.log(a);
  };
  const [secret, setSecret] = useState();
  const [id, setId1] = useState();
  const getSecretKey = async () => {
    try {
      let data = await fetch(`${BaseUrl}/admin/secretfile/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let a = await data.json();

      let id1 = a.details[0]._id;

      setSecret(a.details[0]);
      setId1(id1);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const bdPanel = async (credentials) => {
    try {
      // console.log(credentials);
      let data = await fetch(`${BaseUrl}/bdPanel/login`, {
        method: "POST",
        body: JSON.stringify(credentials),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let res = await data.json();
      // console.log(res);
      if (res.success === 1) {
        toast.success(res.message);
        sessionStorage.setItem("bd", JSON.stringify(res.bd));

        setTimeout(() => {
          window.location.href = "/bd/dashboard";
        }, 1000);
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Widthdrawal
  const [widthdrawal, setWidthdrawal] = useState([]);
  const widthdrawalLists = async (status) => {
    try {
      // let data = await fetch(
      //   `${BaseUrl}/admin/withdrawal/pending?status=${status}`,
      //   {
      //     method: "GET",
      //     // body: JSON.stringify(status),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
      const { data } = await axios.get(
        `${BaseUrl3}/admin/withdrawal/pending?status=${status}`
      );
      console.log(data);

      // let res = await data.json();

      if (data.success === 1) {
        toast.success(data.message);
        setWidthdrawal(data.details);
        // sessionStorage.setItem("bd", JSON.stringify(res.bd));

        setTimeout(() => {
          // window.location.href = "/bd/dashboard";
        }, 1000);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Update Widthdrawal
  const widthdrawalListsUpdate = async (id, status) => {
    try {
      // let data = await fetch(
      //   `${BaseUrl}/admin/withdrawal/pending?status=${status}`,
      //   {
      //     method: "GET",
      //     // body: JSON.stringify(status),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );
      const { data } = await axios.post(
        `${BaseUrl3}/admin/withdrawal/coinrequest/${id}?status=${status}`
      );

      if (data.success == 1) {
        toast.success(data.message);
        // setWidthdrawal(data.details);
        // sessionStorage.setItem("bd", JSON.stringify(res.bd));
        setTimeout(() => {
          // window.location.href = "/bd/dashboard";
        }, 1000);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <>
      <global.Provider
        value={{
          widthdrawalListsUpdate,
          widthdrawal,
          setWidthdrawal,
          widthdrawalLists,
          setLivePage,
          setbd,
          livePage,
          getUserLevel,
          updateUserLevel,
          userLevel,
          page,
          setPage,
          totalPages,
          setTotalPages,
          getVulgar,
          hostRecord,
          aproveRejectVulgar,
          getVulgarResult,
          login,
          users,
          user,
          // viewUsers,
          // viewUser,
          editProfile,
          viewBannerr,
          viewBanner,
          fetchBanner,
          fetchBannerr,
          viewVipp,
          viewVip,
          viewVipS,
          viewVipsS,
          viewTagg,
          viewTag,
          fetchTag,
          fetchTagS,
          viewGifts,
          viewGift,
          fetchGift,
          fetchGiftS,
          viewLiveGifts,
          viewLiveGift,
          fetchlivegif,
          fetchlivegifS,
          viewEntryEffects,
          viewEntryEffect,
          fetchentryeffect,
          fetchentryeffectS,
          viewFrames,
          viewFramess,
          fetchframes,
          fetchframesS,
          viewLiveGiftCategorys,
          viewLiveGiftCategory,
          fetchlivegift,
          fetchlivegiftS,
          viewWallpapers,
          viewWallpaper,
          fetchwallpaper,
          fetchwallpaperS,
          viewCoins,
          viewCoin,
          fetchcoinPanel,
          fetchCoinPanel,
          rechargeHistory,
          rechargeHistorys,
          agencyPanel,
          viewAgencyPanel,
          fetchagencyPanel,
          fetchagencyPanell,
          level,
          viewlevel,
          fetchlevel,
          fetchlevell,
          AgencyLogin,
          CoinPanelLogin,
          getHosts,
          totalHosts,
          fetchHosts,
          fetchHost,
          viewVipSCat,
          viewVipsCatS,
          aprrovedHost,
          getHostByStatus,
          getHostByStatuss,
          liveuser,
          viewliveuser,
          rechargeRecievedHistorys,
          received,
          banStatus,
          adminrechargeHistory,
          adminrechargeHistorys,
          hostCounts,
          viewhostCounts,
          adminframes,
          viewadminframes,
          adminentryEffects,
          viewadminentryEffects,
          getSongs,
          songs,
          setSongs,
          planCounts,
          viewPlan,
          contentCounts,
          viewContent,
          fetchplan,
          FetchPlanss,
          hostrecord,
          secret,
          getSecretKey,
          getHostByStatus1,
          getHostByStatuss1,
          getVideoResult,
          bdPanel,
          id,
        }}
      >
        {children}
      </global.Provider>
    </>
  );
};

export { Context, global };
