import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import $ from 'jquery';
import {toast} from 'react-toastify'
import axios from 'axios'


const FamilyLeave = () => {
    const [userInfo,setUserInfo] = useState("")
    const [userD,setUserD] = useState({})


    const leaveFam = async () => {
        try {
            const {data} = await axios.delete(`${process.env.REACT_APP_BASEURL1}/admin/familyImage/leave?userId=${userD._id}&familyId=${userD.family._id}`)
        } catch (error) {
            console.log(error.message)
        }
    }

    
    useEffect(() => {
        const getUserDetails = async () => {
            try {
                const {data} = await axios.post(`${process.env.REACT_APP_BASEURL1}/admin/familyImage/leaveFamily?username=${userInfo}`)
                if(data.success == 1) {
                  setUserD(data.details[0])
                }
            } catch (error) {
                console.log(error.message)
            }
        }
            getUserDetails()
    },[userInfo])
    // console.log(userD)

    return ( 
        <>
            <section className="content-header">
                <h1>Leave Family</h1>
                <ol className="breadcrumb">
                    <li><Link to="/admin"><i className="fa fa-dashboard"></i> Home</Link></li>
                    <li className="active">Leave Family</li>
                </ol>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <form role="form"encType="multipart/form-data" id="form">
                            <div className="box box-warning">
                                <div className="box-header with-border">
                                    <h3 className="box-title">Leave</h3>
                                </div>
                                <div className="box-body">
                                    {/* <div className="form-group">
                                        <label>Send Coin To*</label>
                                        <select className="form-control" id="sendType" onChange={sendType} name="to">
                                            <option value="">Select</option>
                                            <option value={1}>Coin Agency</option>
                                            <option value={2}>User</option>
                                        </select>
                                        <div className="form-error1 text-danger"></div>
                                    </div>
                                    <div id="subadmin" style={{ display: 'none' }}>
                                        <div className="form-group" id='agency'>
                                            <label>Coin Agency Code*</label>
                                            <input type="text" className="form-control" name="aGuserId" placeholder="Coin Agency Code" onChange={()=>{
                                                $('.form-error2').html('');
                                                checkagency();
                                            }}/>
                                            <div className="form-error2 text-danger"></div>
                                        </div>
                                        <div className="form-group">
                                            <label>Coins*</label>
                                            <input type="text" className="form-control" name="aGcoin" placeholder="Coins" onChange={()=>{
                                                $('.form-error3').html('');
                                            }}/>
                                            <div className="form-error3 text-danger"></div>
                                        </div>
                                    </div> */}
                                    <div id="hide" >
                                        <div className="form-group" id='user'>
                                            <label>Username*</label>
                                            <input type="text" className="form-control" name="userId" placeholder="username" onChange={(e)=>{
                                             setUserInfo(e.target.value)
                                            }}/>
                                            <div className="form-error4 text-danger"></div>
                                        </div>
                                        <div className="form-group" style={{display:"flex", flexDirection:"column"}}>
                                            <label>Family</label>
                                            <div style={{display:"flex",gap:"1rem"}}>
                                            <div>
                                        {userD &&  userD.familyImage !== null &&  <img src={`${userD?.family && userD?.familyImage}`}  style={{borderRadius:"50px"}} height={50} width={50} /> }
                                        {userD.familyImage === null && <span>Not in family</span> }
                                            </div>
                                            <div >
                                                <h5 style={{color:"black",fontWeight:"bold"}}>{userD?.family?.name}</h5>
                                            </div>

                                            </div>
                                            {/* <input type="text" className="form-control" name="coin" placeholder="Coins" onChange={()=>{
                                                $('.form-error5').html('');
                                            }}/> */}
                                            <div className="form-error5 text-danger"></div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {/* <button type="reset" className="btn btn-danger">Cancel</button> */}
                                        <input type="submit" className="btn btn-success pull-right" disabled={userD.familyImage !== null ? false:true} defaultValue="Leave" onClick={() => {

                                            leaveFam()
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FamilyLeave